import React, { useState, useContext } from 'react'
import { GlobalContext } from '../GlobalContext'
import Button from './Button'
import Modal from '../ui-elements/Modal'

type ObjectAny = {
  [key: string]: any
}


type Props = {
  deleteFunction: any
  functionParams: any
  layout?: string
  size?: 'big' | 'small'
  fullwidth?: boolean
  buttonText?: string
  modalText?: string
  variant?: 'gray' | 'warning'
}

function DeleteButton({
  deleteFunction,
  functionParams,
  layout,
  size,
  fullwidth = true,
  buttonText = 'Delete',
  modalText = 'Are you sure you want to delete?',
  variant = 'warning'
}: Props) {


  const [showConfirm, setShowConfirm] = useState(false)

  return <div className={`${fullwidth && 'w-full'}`}>
    <Button
      onClick={() => setShowConfirm(true)}
      text={buttonText}
      variant={variant}
      size={size}
    />

    {showConfirm && <Modal>
      <p className={`text-center`}>{modalText}</p>

      <div className={`mt-3 flex items-center flex-row gap-2`}>
        <Button

          onClick={() => {
            setShowConfirm(false)
            deleteFunction(functionParams)
          }}
          variant={'warning'}
          text={'Confirm'}
          size={size}
        />
        <Button

          onClick={() => {
            setShowConfirm(false)
          }}
          variant={'gray'}
          text={'Cancel'}
          size={size}
        />
      </div>
    </Modal>}

  </div>
}

export default DeleteButton