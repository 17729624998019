export const fridgeName = (fridgeId: string | null, tableData: ObjectStringKeyAnyValue | null) => {
    if (!fridgeId || !tableData) {
        return ' fridge/freezer asset '
    } else {
        const fridgeName = fridgeId && tableData && tableData.Assets && tableData.Assets[fridgeId] && tableData.Assets[fridgeId].AssetName
        if (fridgeName) {
            return ` "${fridgeName}" (#${fridgeId}) `
        } else {
            return ` fridge ID #${fridgeId} `
        }
    }
}

