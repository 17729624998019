
import React, { useEffect, useState, Children, useContext, useRef } from 'react'
import { GlobalContext } from '../GlobalContext'
import { Link } from "react-router-dom"
import Button from '../formfields/Button'
import Modal from '../ui-elements/Modal'
import Spinner from '../ui-elements/Spinner'
import { useVideo } from '../context/video/VideoContext'


type Props = {
  children?: any
}

function VideoBackground({
  children
}: Props) {

  const {
    tableData,
    isVideoMode,
    isVideoPlaying,
    setIsVideoPlaying
  } = useContext(GlobalContext)

  const initialWindowSize = {
    width: window.innerWidth,
    height: window.innerHeight,
  }
  const { streamToMyCanvas, stopStreamingToMyCanvas, availableCameras, selectedCamera, selectCamera, cameraState } = useVideo()
  const videoCanvasRef = useRef<HTMLCanvasElement | null>(null)
  const [windowSize, setWindowSize] = useState<ObjectStringKeyAnyValue | null>(initialWindowSize)


  useEffect(() => {
    if (!isVideoMode) {
      setIsVideoPlaying(false)
    }
  }, [isVideoMode])


  useEffect(() => {
    if (isVideoPlaying) {
      //console.log('start streaming')
      streamToMyCanvas(videoCanvasRef)
      // stop the camera when the component unmounts
    } else {
      stopStreamingToMyCanvas(videoCanvasRef)
    }


  }, [isVideoPlaying])

  useEffect(() => {
    const handleResize = () => {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      })
    }
    window.addEventListener('resize', handleResize)
    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])


  return <div className='z-0 w-full h-full'>
    <div className='z-50 w-full h-full'
      style={{
        pointerEvents: 'auto'
      }}
    >{children}</div>
    <canvas ref={videoCanvasRef}
      className={`fixed top-0 ${isVideoMode ? '' : 'hidden'}`}
      style={{
        // border: '2px solid',
        zIndex: '-10',
        pointerEvents: 'none'
      }}
      width={windowSize!.width}
      height={windowSize!.height}
    />
  </div>
}

export default VideoBackground