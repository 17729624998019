
import React, { useContext, Children } from 'react'
import { GlobalContext } from '../GlobalContext'
import { Link, useParams } from 'react-router-dom'
import Button from '../formfields/Button'
import Spinner from '../ui-elements/Spinner'



type Props = {
  backButtonUrl: string
  backButtonText?: string
  backButtonAction?: AnyFunction | null
  nextButtonUrl?: string | null
  nextButtonText?: string
  nextButtonAction?: AnyFunction | null
  answerId?: string | null
}

function FooterNavButtons({
  backButtonUrl,
  backButtonText = 'Back',
  backButtonAction,
  nextButtonUrl,
  nextButtonText = 'Next',
  nextButtonAction,
  answerId
}: Props) {

  const {
   isVideoMode,
    isVideoPlaying
  } = useContext(GlobalContext)
  const { questionId, checklistId } = useParams()
  // answerId && console.log(answerId)
  // console.log(nextButtonUrl)
  // console.log(nextButtonAction)

  return <div className={`
  ${isVideoMode ? '' : ' border-t-2 border-gray-300 bg-white '} 
  fixed bottom-0 w-full flex flex-col items-center justify-center `}>
    {/* {answerId && <p className={`text-xs text-center mt-2`}>{answerId}</p>} */}
    <div className={`max-w-3xl w-full px-5 py-3 flex flex-row gap-5`}>
      {backButtonUrl && !backButtonAction && <Button
        internalLinkUrl={backButtonUrl}
        text={backButtonText}
        disabled={false}
        variant={'primary'}
        // size={'big'}
      />}

      {backButtonAction && <Button
        onClick={backButtonAction}
        text={`${backButtonText}`}
        disabled={false}
        variant={'primary'}
        // size={'big'}
      />}

      {nextButtonUrl && !nextButtonAction && <Button
        internalLinkUrl={nextButtonUrl}
        text={nextButtonText}
        disabled={false}
        variant={'primary'}
        // size={'big'}
      />}

      {nextButtonAction && <Button
        onClick={nextButtonAction}
        text={`${nextButtonText}`}
        disabled={false}
        variant={'primary'}
        // size={'big'}
      />}

    </div>
  </div>
}
export default FooterNavButtons