
import React, { useContext, Children } from 'react'
import { GlobalContext } from '../GlobalContext'
import { Link } from 'react-router-dom'
import Button from '../formfields/Button'
import Spinner from '../ui-elements/Spinner'
import RedAlert from '../ui-elements/RedAlert'



type Props = {
  questionId?: string
  checklistId?: string
  setQuestionAnswer: React.Dispatch<React.SetStateAction<any>>
  defaultValue: null | string | string[]
  questionOptions: null | ObjectStringKeyAnyValue
}

function QuestionSelectOne({
  questionId,
  checklistId,
  setQuestionAnswer,
  defaultValue,
  questionOptions
}: Props) {

  const {
    tableData
  } = useContext(GlobalContext)

  
  return <div className={`w-full flex flex-row gap-2 items-center justify-center`}>

    {(!questionOptions || questionOptions.length < 1) && <RedAlert>No options for this question</RedAlert>}

    {questionOptions && questionOptions.length > 0 && <div className={`flex flex-col gap-2`}>
      {questionOptions.map((option: string, index: number) => {
        return <div key={index}>
          {/* <label
            onClick={(e: any) => setQuestionAnswer(option)}
            className={`flex flex-row gap-1 items-center`}
          >
            <input
              name={questionId}
              type='radio'
              value={option}
              checked={defaultValue === option ? true : false}
            />
            {option}
          </label> */}
          <Button
            onClick={(e: any) => setQuestionAnswer(option)}
            text={option}
            variant={defaultValue === option ? 'primary' : 'white'}
            size={'big'}
            wordWrap={true}
          />
        </div>
      })}
    </div>}

  </div>
}
export default QuestionSelectOne