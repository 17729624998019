
import React, { useContext, Children } from 'react'
import { GlobalContext } from '../GlobalContext'
import { useParams } from 'react-router-dom'
import YellowAlert from '../ui-elements/YellowAlert'



type Props = {
  titleText?: string
  instructionsText?: string
  subheadingText?: string | null
}

function HeaderDetails({
  titleText,
  instructionsText,
  subheadingText,
}: Props) {

  const {
    isVideoMode
  } = useContext(GlobalContext)
 



  return <div className={`
  ${isVideoMode ? ' fixed w-full max-w-3xl px-5 ' : ' w-full '}`}>
    

    <div className={`
    ${isVideoMode ? ' mt-4 rounded ' : ' border-b-2 border-gray-300 '}
    w-full flex flex-row gap-4 items-center justify-center 
    `}
      style={{
        background: `rgba(255, 255, 255, ${isVideoMode ? '0.8' : '1'})`
      }}
    >
      
      <div className={`max-w-3xl w-full px-5 py-2 flex flex-col gap-0 items-center justify-center text-center`}>


        {titleText && <h3 className={`font-righteous text-3xl font-brandblue`}>{titleText}</h3>}

        {subheadingText && <p className={`mt-2 w-full text-sm text-gray-600 italic`}>
          {subheadingText}</p>}

        {instructionsText && <div className={`mt-2 w-full`}><YellowAlert size='small' icon={false}>{instructionsText}</YellowAlert></div>} 

        
      </div>
    </div>
  </div>
}
export default HeaderDetails