import React, { useEffect, useState, useContext } from 'react'
import {
  BrowserRouter as Router,
  Link,
  Navigate,
  useNavigate,
  useParams
} from "react-router-dom"
import { GlobalContext } from '../GlobalContext'
import Button from '../formfields/Button'
import Card from '../ui-elements/Card'
import { padOutZeros } from '../utils/padOutZeros'

type Props = {
  checkWindow: ObjectStringKeyAnyValue
  checkWindowId: string
}


function SummaryCheckWindow({
  checkWindow,
  checkWindowId
}: Props) {

  const {
    brandstyles,
    tableData,
    userData,
    fridges,
    currentOrganisation,
    switchCurrentOrganisation,
    sendMessageToWebsocket,
    checklistSetupFields,
    setChecklistSetupFields
  } = useContext(GlobalContext)

  const { checklistId } = useParams()
  const daysArray = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday']
  const shortDaysArray = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun']




  return <div
    className={`border border-gray-400 rounded px-2 py-1 bg-white`}
  >
    <p className={`font-bold`}>
      {checkWindow && checkWindow.CheckWindowName && typeof checkWindow.CheckWindowName === 'string' ?
        checkWindow.CheckWindowName :
        "Untitled check window"
      }</p>
    <div className={`text-xs text-gray-700 flex flex-row gap-1`}>
      <p className={`text-xs text-gray-700`}>

        {padOutZeros(checkWindow.start.hours)}:{padOutZeros(checkWindow.start.minutes)} - {padOutZeros(checkWindow.end.hours)}:{padOutZeros(checkWindow.end.minutes)} on
        {checkWindow.days.sort().map((day: number, index: number) => {
          let suffix = ', '
          if (checkWindow.days.length - 2 === index) suffix = ' & '
          if (checkWindow.days.length - 1 === index) suffix = ''
          return <span key={index}> {daysArray[day]}{suffix} </span>
        })}
      </p>
    </div>
  </div>
}
export default SummaryCheckWindow