
import React, { useContext, Children } from 'react'
import { GlobalContext } from '../GlobalContext'
import { Link } from 'react-router-dom'
import Button from '../formfields/Button'
import Spinner from '../ui-elements/Spinner'



type Props = {
    question: ObjectStringKeyAnyValue
    questionId?: string
    checklistId?: string
    setQuestionAnswer: React.Dispatch<React.SetStateAction<any>>
    defaultValue: any
    handleChangeMultiText: AnyFunction
}

function QuestionMultipleTextFields({
    question,
    questionId,
    checklistId,
    setQuestionAnswer,
    defaultValue,
    handleChangeMultiText
}: Props) {

    const {
        tableData
    } = useContext(GlobalContext)





    return <div className={`w-full flex flex-col gap-2`}>

        {question.QuestionFieldNames.map((fieldName: string, index: number) => {
            return <div key={index} className={`flex flex-col gap-1`}>
                {fieldName}:
                <input
                    className={`bg-white text-center drop-shadow-lg border border-gray-300 text-gray-900 rounded hover:opacity-90-lg focus:ring-blue-500 focus:border-blue-500 block w-full text-lg px-5 py-3`}
                    onChange={(e) => handleChangeMultiText(fieldName, e.target.value)}
                    type={`text`}
                    value={defaultValue && defaultValue[fieldName] || ''}
                />
            </div>
        })}

    </div>
}
export default QuestionMultipleTextFields