



export const sortAllQuestionAnswersForCheckPeriod = (checkPeriodId: string, tableData: ObjectStringKeyAnyValue) => {
  let allQuestionAnswersForCheckPeriod: any = []


  // find all questions which are NOT follow on questions
  const parentQuestions = []
  for (const questionId in tableData.ChecklistQuestions) {
    const thisQuestion = tableData.ChecklistQuestions[questionId]
    if (!thisQuestion.parentQuestionId && !thisQuestion.parentActionId) {
      parentQuestions.push(thisQuestion)
    }
  }

  // sort parent questions by question order
  parentQuestions.sort((a, b) => parseInt(a.QuestionOrder) - parseInt(b.QuestionOrder))




  parentQuestions.forEach((question: any) => {
    // find all non repeat answers for each parent question,
    // then find all repeat answers for each parent question
    const nonRepeatAnswers = []
    const repeatAnswers = []
    for (const answerId in tableData.ChecklistAnswers) {
      const thisAnswer = tableData.ChecklistAnswers[answerId]
      if (
        thisAnswer.QuestionId === question.Id
        && thisAnswer.ChecklistPeriodId === checkPeriodId
      ) {
        if (thisAnswer.repeatAnswerAfter && thisAnswer.repeatAnswerAfter.number) {
          repeatAnswers.push(thisAnswer)
        } else {
          nonRepeatAnswers.push(thisAnswer)
        }
      }
    }
    repeatAnswers.sort((a, b) => parseInt(a.repeatAnswerAfter.number) - parseInt(b.repeatAnswerAfter.number))
    nonRepeatAnswers.forEach((answer: any) => { allQuestionAnswersForCheckPeriod.push(answer) })
    repeatAnswers.forEach((answer: any) => { allQuestionAnswersForCheckPeriod.push(answer) })

    // find all follow on questions for each question
    // sort them by question order
    // find all answers for each follow on question
    const followUpQuestions = []
    for (const questionId in tableData.ChecklistQuestions) {
      const thisQuestion = tableData.ChecklistQuestions[questionId]
      if (thisQuestion.parentQuestionId === question.Id) {
        followUpQuestions.push(thisQuestion)
      }
    }

    // sort follow up questions by question order
    followUpQuestions.sort((a, b) => parseInt(a.QuestionOrder) - parseInt(b.QuestionOrder))



    followUpQuestions.forEach((question: any) => {
      // find all non repeat answers for each parent question,
      // then find all repeat answers for each parent question
      const nonRepeatAnswers = []
      const repeatAnswers = []
      for (const answerId in tableData.ChecklistAnswers) {
        const thisAnswer = tableData.ChecklistAnswers[answerId]
        if (
          thisAnswer.QuestionId === question.Id
          && thisAnswer.ChecklistPeriodId === checkPeriodId
        ) {
          if (thisAnswer.repeatAnswerAfter) {
            repeatAnswers.push(thisAnswer)
          } else {
            nonRepeatAnswers.push(thisAnswer)
          }
        }
      }
      repeatAnswers.sort((a, b) => parseInt(a.repeatAnswerAfter) - parseInt(b.repeatAnswerAfter))
      nonRepeatAnswers.forEach((answer: any) => { allQuestionAnswersForCheckPeriod.push(answer) })
      repeatAnswers.forEach((answer: any) => { allQuestionAnswersForCheckPeriod.push(answer) })
    })

  })


  return allQuestionAnswersForCheckPeriod
}