
import React, { useContext, Children } from 'react'
import { GlobalContext } from '../GlobalContext'
import { Link } from 'react-router-dom'
import Button from '../formfields/Button'
import Spinner from '../ui-elements/Spinner'



type Props = {
  questionId?: string
  checklistId?: string
  setQuestionAnswer: React.Dispatch<React.SetStateAction<any>>
  defaultValue: null | string | string[]
}

function QuestionSelectOne({
  questionId,
  checklistId,
  setQuestionAnswer,
  defaultValue
}: Props) {

  const {
    tableData
  } = useContext(GlobalContext)


  
  return <div className={`w-full flex flex-row gap-2 items-center justify-center`}>


    <Button
      onClick={(e: any) => setQuestionAnswer('Yes')}
      text={'Yes'}
      variant={defaultValue === 'Yes' ? 'primary' : 'white'}
      size={'big'}
    />
    <Button
      onClick={(e: any) => setQuestionAnswer('No')}
      text={'No'}
      variant={defaultValue === 'No' ? 'primary' : 'white'}
      size={'big'}
    />


  </div>
}
export default QuestionSelectOne