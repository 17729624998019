import React, { useEffect, useState, Children, useContext } from 'react'
import { GlobalContext } from '../GlobalContext'
import { Link, useNavigate, useParams } from "react-router-dom"
import Button from '../formfields/Button'
import Modal from '../ui-elements/Modal'
import GuestUserLoginForm from './GuestUserLoginForm'

type Props = {
    orgMismatchDialog: string | null
    setOrgMismatchDialog: React.Dispatch<React.SetStateAction<any>>
}


function OrgMismatchDialog({
    orgMismatchDialog,
    setOrgMismatchDialog
}: Props) {

    const {
        tableData,
        userData,
        fridges,
        currentOrganisation,
        switchCurrentOrganisation,
        sendMessageToWebsocket,
        orgIdFromQrCode,
        handleLogout
    } = useContext(GlobalContext)

    const navigate = useNavigate()
    const { checklistId } = useParams()
    const [showForm, setShowForm] = useState(false)




    const currentOrgName = userData && currentOrganisation && JSON.parse(userData.organisationsForUser)[currentOrganisation] ? JSON.parse(userData.organisationsForUser)[currentOrganisation] : null

    const qrCodeOrgName = userData && currentOrganisation && JSON.parse(userData.organisationsForUser)[orgIdFromQrCode] ? JSON.parse(userData.organisationsForUser)[orgIdFromQrCode] : null

    const setMismatchedChecklistId = (checklistId: any) => {
        checklistId && window.localStorage.setItem('mismatchedChecklistId', checklistId)
    }


    return <Modal>
        <div className={`gap-4 flex flex-col items-center justify-center text-center`}>



            {qrCodeOrgName && currentOrgName ? <p>
                Sorry, the QR code you have scanned is for organisation "{qrCodeOrgName}", and you are logged in to "{currentOrgName}"
            </p> :
                <p>
                    Sorry, the QR code you have scanned does not match the organisation you are logged in to.
                </p>}

            {!showForm && <div className={`w-full gap-2 flex flex-col items-center justify-center text-center`}>
                {orgMismatchDialog == 'switchOrgAsSameUser' && <Button
                    onClick={() => {
                        switchCurrentOrganisation(orgIdFromQrCode)
                        setOrgMismatchDialog(null)
                    }}
                    text={qrCodeOrgName ? `Switch to ${qrCodeOrgName}` : `Switch account`}
                    variant={`primary`}
                // size={'big'}
                />}

                {orgMismatchDialog == 'switchOrgAsGuest' && <Button
                    onClick={() => {
                        setMismatchedChecklistId(checklistId)
                        setOrgMismatchDialog(null)
                        handleLogout()
                    }}
                    text={`Log in as a guest`}
                    variant={`primary`}
                // size={'big'}
                />}

                {orgMismatchDialog == 'loginAsDifferentUser' && <Button
                    onClick={() => {
                        setMismatchedChecklistId(checklistId)
                        setOrgMismatchDialog(null)
                        handleLogout()
                    }}
                    text={`Log in as a new user`}
                    variant={`primary`}
                // size={'big'}
                />}

                <Button
                    onClick={() => {
                        navigate(`/`)
                        setOrgMismatchDialog(null)
                    }}
                    text={`Cancel`}
                    variant={`gray`}
                // size={'big'}
                />
            </div>}

        </div>
    </Modal>
}

export default OrgMismatchDialog