

import React, { useContext, useState } from 'react'
import { GlobalContext } from '../GlobalContext'
import CloudformationOutputs from '../CloudformationOutputs.json'
import Modal from '../ui-elements/Modal'

type Props = {
  questionAnswer: any
  isTemperature?: boolean
}

function FormatQuestionAnswer({
  questionAnswer,
  isTemperature = false,
}: Props) {




  return <span>

    {questionAnswer.Answer && <>

      {questionAnswer.Answer instanceof Array && 
      <>{questionAnswer.Answer.map((option: string, index: number) => {
        return `${option}${index + 1 < questionAnswer.Answer.length ? ', ' : ''}`
      })}</>}

      {questionAnswer.Answer instanceof Object && !(questionAnswer.Answer instanceof Array) && 
      <>{Object.keys(questionAnswer.Answer).map((keyName: string, index: number) => {
        return `${keyName}: ${questionAnswer.Answer[keyName] || ''}${index + 1 < Object.keys(questionAnswer.Answer).length ? ', ' : ''}`
      })}</>}


      {typeof questionAnswer.Answer === 'string' ? `${questionAnswer.Answer}${isTemperature ? '°' : ''}` : ''}

    </>}
  </span>
}

export default FormatQuestionAnswer