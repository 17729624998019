
import React, { useContext, Children } from 'react'
import { GlobalContext } from '../GlobalContext'
import { useParams } from 'react-router-dom'
import YellowAlert from '../ui-elements/YellowAlert'



type Props = {
  children: any
}

function PhotoInstruction({
 children
}: Props) {


  return <div className="fixed w-full max-w-3xl px-5 flex justify-center items-center top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
    <div className="mt-4 rounded w-full flex flex-row gap-4 items-center justify-center" style={{ background: 'rgba(255, 255, 255, 0.8)' }}>
      <div className="max-w-3xl w-full px-5 py-2 flex flex-col gap-0 items-center justify-center text-center" style={{'fontSize':23}}>
        {children}
      </div>
    </div>
  </div> 
}
export default PhotoInstruction