
import React, { useContext, Children, useState, useEffect } from 'react'
import { GlobalContext } from '../GlobalContext'
import { Link, useNavigate, useParams } from 'react-router-dom'
import Spinner from '../ui-elements/Spinner'
import HeaderDetails from '../shared-components/HeaderDetails'
import RedAlert from '../ui-elements/RedAlert'
import Button from '../formfields/Button'
import { subscribe } from '../utils/pubsub'
import Modal from '../ui-elements/Modal'
import { findNextQuestionUrl } from '../utils/findNextQuestionUrl'



type Props = {

}

function AddAnswerToQuestion({

}: Props) {

    const {
        tableData,
        setShowModal,
        sendMessageToWebsocket
    } = useContext(GlobalContext)

    const navigate = useNavigate()
    const { checkPeriodId, questionId } = useParams()
    const [subheadingText, setSubheadingText] = useState('')
    const [newAnswerId, setNewAnswerId] = useState(null)

    const thisQuestion = questionId && tableData && tableData.ChecklistQuestions && tableData.ChecklistQuestions[questionId]
    const maxRepeatAnswersForThisQuestion = thisQuestion && thisQuestion.RepeatQuestion && thisQuestion.RepeatQuestion.numberOfTimes && thisQuestion.RepeatQuestion.numberOfTimes.max
    const repeatAnswersForThisQuestion: any[] = tableData && tableData.ChecklistAnswers ? Object.values(tableData.ChecklistAnswers).filter((answer: any) => {
        return answer.QuestionId === questionId && answer.repeatAnswerAfter && answer.ChecklistPeriodId === checkPeriodId
    }) : []
    repeatAnswersForThisQuestion.sort((a, b) => parseInt(b.repeatAnswerAfter.number) - parseInt(a.repeatAnswerAfter.number))
    const mostRecentAnswerId = repeatAnswersForThisQuestion[0] && repeatAnswersForThisQuestion[0].Id
    const isAbleToAddMoreAnswers = checkPeriodId && questionId && repeatAnswersForThisQuestion && maxRepeatAnswersForThisQuestion && repeatAnswersForThisQuestion.length < maxRepeatAnswersForThisQuestion


    useEffect(() => {
        // navigate once the answer object has appeared
        if (newAnswerId && tableData && tableData.ChecklistAnswers && tableData.ChecklistAnswers[newAnswerId]) {
            const nextUrl = `/checklists/${checkPeriodId}/${newAnswerId}`
            //console.log(nextUrl)
            navigate(nextUrl)
            setShowModal(null)
        }
    }, [tableData, newAnswerId])

    const AddAnswerToQuestion = (questionId: string, checkPeriodId: string) => {
        setShowModal({ "spinner": 'Adding answer...' })
        const payload: ObjectStringKeyAnyValue = {
            action: "checklistsClientApp",
            subAction: "addAnswerObjectToQuestion",
            questionId: `${questionId}`,
            checkPeriodId: `${checkPeriodId}`
        }
        sendMessageToWebsocket(JSON.stringify(payload))
        const unsubscribe = subscribe("answerObjectAddedToQuestion", data => {
            if (data && data.nextAnswerId && data.questionId && data.questionId === questionId) {
                setNewAnswerId(data.nextAnswerId)
            } else {
                console.log(data)
            }
            unsubscribe()
        })
    }

    //console.log(repeatAnswersForThisQuestion)


    return <div
        className={`w-full flex-1 h-full flex flex-col justify-between gap-2 items-center`}
    >

        <HeaderDetails
            titleText={` ${thisQuestion ? thisQuestion.QuestionText : 'Unknown Question'}`}
            instructionsText={thisQuestion && thisQuestion.QuestionInstructions}
            subheadingText={subheadingText}
        />


        <div className={`max-w-3xl w-full px-5 py-5 flex flex-col gap-4 items-center`}>

            {isAbleToAddMoreAnswers &&
                <div className={`flex flex-col gap-4 items-center`}>
                    <p>
                        You are able to add up to {maxRepeatAnswersForThisQuestion} repeat answers for this question.
                        Currently there are {repeatAnswersForThisQuestion.length}.
                    </p>

                    <Button
                        onClick={() => AddAnswerToQuestion(thisQuestion.Id, checkPeriodId)}
                        text={`Add another answer`}
                        fullwidth={true}
                        size={`big`}
                    />

                </div>}

            {!isAbleToAddMoreAnswers &&
                <div>
                    <RedAlert>You can not add more answers to this question.</RedAlert>
                </div>
            }


        </div>

        <div className={`border-t-2 border-gray-300 bg-white '} 
  fixed bottom-0 w-full flex flex-col items-center justify-center `}>

            <div className={`max-w-3xl w-full px-5 py-3 flex flex-row gap-2`}>

                <Button
                    internalLinkUrl={`/checklists/${checkPeriodId}/`}
                    text={`Back`}
                    disabled={false}
                    variant={'primary'}
                // size={'big'}
                />
                <Button
                    internalLinkUrl={findNextQuestionUrl(mostRecentAnswerId, checkPeriodId, tableData, 'skipAddingAnswer')}
                    text={`Next`}
                    disabled={false}
                    variant={'primary'}
                // size={'big'}
                />


            </div>
        </div>
    </div>
}
export default AddAnswerToQuestion