import React, { useContext, useEffect, useState } from 'react'
import { GlobalContext } from '../GlobalContext'
import Button from '../formfields/Button'
import { useVideo } from '../context/video/VideoContext';
import Card from '../ui-elements/Card'
import Modal from '../ui-elements/Modal'
import AddButton from '../formfields/AddButton'



type Props = {
  jpegString: string
}

function PhotoPreview({
  jpegString,
}: Props) {


  const {
    setIsVideoMode,
    isVideoPlaying,
    setIsVideoPlaying
  } = useContext(GlobalContext)


  return <Card><div className={`w-full flex flex-col gap-4 justify-center items-center`}>

    <p className={`text-left font-righteous text-brandblue text-xl`}>Photo captured!</p>

    <div className={`w-full flex flex-col gap-3 items-center`}>
      <img
        src={jpegString}
        //src={'https://placehold.co/600x2400'}
        className={`rounded`}
        style={{ maxHeight: '60vh' }}
        alt={''}
      />
      {/* {QRCodesPresent && JSON.stringify(QRCodesPresent)} */}
    </div>
  </div>
  </Card>
}
export default PhotoPreview