import React, { useEffect, useState, useContext } from 'react'
import Button from '../formfields/Button'
import { GlobalContext } from '../GlobalContext'
import HeaderDetails from '../shared-components/HeaderDetails'
import Card from '../ui-elements/Card'
import Spinner from '../ui-elements/Spinner'
import ChecklistSummary from './ChecklistSummary'
import SummaryCheckWindow from './SummaryCheckWindow'
import { json } from 'stream/consumers'
import NoActiveCheckPeriods from './NoActiveCheckPeriods'
import NoChecklists from './NoChecklists'


type Props = {

}

function AllChecklists({
}: Props) {

  const {
    tableData
  } = useContext(GlobalContext)




  return <div className={`w-full flex-1 h-full flex flex-col items-center gap-2`}>

    <HeaderDetails
      titleText={'All checklists'}
    />


    <div className={`max-w-3xl w-full px-5 py-5 flex flex-col gap-4`}>
 

      {tableData && tableData.Checklists && tableData.ChecklistPeriods ?
        <div className={`flex flex-col gap-4`}>
          {Object.keys(tableData.Checklists).length > 0 ?
            <div className={`flex flex-row flex-wrap gap-2 text-xs`}>
              {Object.keys(tableData.Checklists).map((checklistId, index) => {
                const thisChecklist = tableData.Checklists[checklistId] || null
                return <ChecklistSummary
                  key={index}
                  checklistId={checklistId}
                  thisChecklist={thisChecklist}
                />
              })}
            </div>
            :
            <NoChecklists />
          }
        </div>
        :
        <Spinner><p>Loading...</p></Spinner>}


    </div>
  </div>
}
export default AllChecklists