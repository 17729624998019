import Card from "../ui-elements/Card"

type Props = {
}

function NoChecklists({
}: Props) {

    return <div className={`animate-fadein w-full mt-2`}>
        <Card fullwidth={true}>
            <div className={` w-full flex flex-col gap-1 items-center justify-center`}>
                <p className={`text-4xl`}>⛔️</p>
                <h4 className={`font-righteous text-brandblue text-2xl`}>You have no checklists set up yet</h4>
                <p className={`text-lg`}>Head to your <a href={`https://dashboard.checqr.net/`} className={`underline`}>dashboard</a> to set some up,</p>
            </div>
        </Card>
    </div>
}
export default NoChecklists