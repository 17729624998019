import React, { useEffect, useState, Children, useContext } from 'react'
import { GlobalContext } from '../GlobalContext'

type Props = {
  total: number
  subtotal: number
}

function ProgressBar({
  total,
  subtotal
}: Props) {

  return <div className={`w-full rounded bg-tealgreen`}>
    <div className={`${subtotal === total && 'rounded-r'} rounded-l bg-brandblue py-2`} style={{
      width: `${Math.round(subtotal / total * 100)}%`
    }}></div>
  </div>
}
export default ProgressBar