export const findQuestionsForAssetId = (assetId, tableData) => {
  //console.log(`finding questions for id: ${assetId}`)
  let results = {}
  if (tableData?.ChecklistQuestions) {
    for (const questionId in tableData.ChecklistQuestions) {
      const thisQuestion = tableData.ChecklistQuestions[questionId]
      // check if this fridge ID has a question associated with it
      if (thisQuestion.FridgeId === assetId) {
        results[questionId] = thisQuestion
        if (tableData.Checklists[thisQuestion.ChecklistId]) {
          results[questionId]['ChecklistName'] = tableData.Checklists[thisQuestion.ChecklistId].ChecklistName
        }
      }
    }
  }
  return results
}