
import React, { useContext } from 'react'
import { GlobalContext } from '../GlobalContext'
import { Link } from 'react-router-dom'

type ObjectAny = {
    [key: string]: any
}

type Props = {
    onClick?: any
    internalLinkUrl?: string
    externalLinkUrl?: string
    text: string
    variant?: 'primary' | 'secondary' | 'warning' | 'gray'
    size?: 'big' | 'small'
    fullwidth?: boolean
    align?: string
}

function AddButton({
    onClick,
    text,
    variant = 'primary',
    size,
    fullwidth = true,
    align = 'center'
}: Props) {


    const classString = `
 p-1 flex flex-row items-center gap-3 bg-white border border-gray-300 text-gray-900 rounded hover:opacity-80 cursor-pointer whitespace-nowrap 
  ${fullwidth === true && 'w-full'}
  ${align === 'left' ? 'text-left' : 'text-center'}
  `
 

    return <div
        className={classString}
        onClick={onClick}
    >
        <div className={`rounded bg-green-300 w-6 h-6 flex flex-col items-center justify-center`}>✚</div>
        <p className={`text-sm px-1`}>{text}</p>
    </div>
}
export default AddButton