
import { calculateRepeatAnswers } from "./calculateRepeatAnswers"
import { sortAllQuestionAnswersForCheckPeriod } from "./sortAllQuestionAnswersForCheckPeriod"


const isAnswerLocked = (questionAnswer) => {
  if (questionAnswer && questionAnswer['LockedUntil']) {
    const currentTimeStamp = new Date().getTime()
    const lockedUntilTimeStamp = questionAnswer['LockedUntil']
    const dateDiffValue = lockedUntilTimeStamp - currentTimeStamp
    if (dateDiffValue > 0) {
      return true
    } else {
      return false
    }
  } else {
    return false
  }
}




export const findNextQuestionUrl = (currentQuestionAnswerId, checkPeriodId, tableData, behaviour = '') => {


  console.log("Finding next question url")
  // console.log(behaviour)
  // console.log(tableData)
  // console.log(checkPeriodId)

  let returnUrl = '/checklists/'

  // back to main page if no check period id or table data
  if (!checkPeriodId || !tableData) {
    returnUrl = '/'
  } else {

    // get all question answers for this check period
    const allQuestionAnswersArray = sortAllQuestionAnswersForCheckPeriod(checkPeriodId, tableData)


    // filter out any answers that are not pending
    // count any answers that are locked
    let availableAnswers = []
    let lockedAnswers = []
    allQuestionAnswersArray.forEach((answer) => {
      const isThisAnswerLocked = isAnswerLocked(answer)
      if (answer.Pending === true) {
        if (isThisAnswerLocked) {
          lockedAnswers.push(answer)
        } else {
          availableAnswers.push(answer)
        }
      }
    })
 
    // if no current answer is provided, return the first in the list
    if (!currentQuestionAnswerId && availableAnswers[0]) {
      const answerId = availableAnswers[0].Id
      returnUrl = `/checklists/${checkPeriodId}/${answerId}`
    }

    // if an answer is provided...
    else if (currentQuestionAnswerId) {

      // if the behaviour is confirm, return the confirm page
      if (behaviour === 'confirm') {
        returnUrl = `/checklists/${checkPeriodId}/confirm/${currentQuestionAnswerId}`
      } else {

        // if its possible to add more answers to the question, prompt for this
        const thisAnswer = tableData && tableData.ChecklistAnswers && tableData.ChecklistAnswers[currentQuestionAnswerId]
        const questionId = thisAnswer ? thisAnswer.QuestionId : null
        const thisQuestion = tableData && tableData.ChecklistQuestions && tableData.ChecklistQuestions[questionId]
        const { maxRepeatAnswersForThisQuestion, repeatAnswersForThisQuestion } = calculateRepeatAnswers(allQuestionAnswersArray, thisQuestion)
        const currentAnswerRepetitionNumber = thisAnswer && thisAnswer.repeatAnswerAfter && thisAnswer.repeatAnswerAfter.number
        //console.log(`🥝 maximum number of answers: ${maxRepeatAnswersForThisQuestion}: current number of answers: ${repeatAnswersForThisQuestion} this is answer repetition number: ${currentAnswerRepetitionNumber}`)

        if (
          questionId
          && maxRepeatAnswersForThisQuestion
          && repeatAnswersForThisQuestion < maxRepeatAnswersForThisQuestion
          && currentAnswerRepetitionNumber
          && parseInt(currentAnswerRepetitionNumber) === repeatAnswersForThisQuestion
          && behaviour !== 'skipAddingAnswer'
        ) {
          returnUrl = `/checklists/${checkPeriodId}/add-answer/${questionId}`
        }

        else {


          // otherwise, find the remaining pending answers
          const filteredAnswersArray = allQuestionAnswersArray.filter((answer) => {
            return (answer.Pending === true && !isAnswerLocked(answer)) || answer.Id === currentQuestionAnswerId
          })
          const currentAnswerIndex = filteredAnswersArray.findIndex((answer) => answer.Id === currentQuestionAnswerId)
          const pendingAnswersBeforeThisOne = filteredAnswersArray.slice(0, currentAnswerIndex)
          const pendingAnswersAfterThisOne = filteredAnswersArray.slice(currentAnswerIndex + 1)


          // if there is a pending answer after this, proceed
          if (pendingAnswersAfterThisOne.length > 0) {
            const answerId = pendingAnswersAfterThisOne[0].Id
            returnUrl = `/checklists/${checkPeriodId}/${answerId}`
          } else {

            // if there are no pending answers after this, check if there are any pending answers before this
            if (pendingAnswersBeforeThisOne.length > 0) {
              const answerId = pendingAnswersBeforeThisOne[0].Id
              returnUrl = `/checklists/${checkPeriodId}/${answerId}`
            } else {
              // if there are no pending answers, go back to the checklist
              if (lockedAnswers.length > 0) {
                returnUrl = `/checklists/${checkPeriodId}/`
              } else {
                console.log("Finding next question url - This is where it should check for sign off")
                if(tableData && tableData['Organisation'] && tableData['Organisation']['ChecklistOptions'] && tableData['Organisation']['ChecklistOptions']['RequireSignoff'] === true){
                  returnUrl = `/checklists/${checkPeriodId}/complete-checklist`
                } else {
                  returnUrl = `/checklists/${checkPeriodId}/`
                }
              }
            }
          }
        }
      }
    }
  }

  //console.log(`🥝 returnUrl: ${returnUrl}`) 
  return returnUrl

}