
import React, { useContext, useRef, useEffect, useState } from 'react'
import { GlobalContext } from '../GlobalContext'
import { Link, useNavigate, useParams } from 'react-router-dom'
import Button from '../formfields/Button'
import Spinner from '../ui-elements/Spinner'
import { useVideo } from '../context/video/VideoContext';
import FooterNavButtons from '../shared-components/FooterNavButtons'
import PhotoNavigation from './PhotoNavigation'
import PhotoPreview from './PhotoPreview'
import Modal from '../ui-elements/Modal'
import Card from '../ui-elements/Card'
import { findQuestionsForAssetId } from '../utils/findQuestionsForAssetId'
import { checkIfAssetExists } from '../utils/checkIfAssetExists'
import { getAssetIdFromUrl } from '../utils/getAssetIdFromUrl'
import HeaderDetails from '../shared-components/HeaderDetails'



type Props = {
}

function ScanQrCode({
}: Props) {

  const navigate = useNavigate()
  const { checklistId } = useParams()
  const { QRCodesPresent, setQRCodesSearchOptions, captureAsJPEGString } = useVideo()
  const [showInvalidAssetModal, setShowInvalidAssetModal] = useState(false)
  const [showQuestionsModal, setShowQuestionsModal] = useState(false)
  const [questionsForAssetId, setQuestionsForAssetId] = useState<ObjectStringKeyAnyValue | null>(null)


  const {
    tableData,
    setIsVideoMode,
    isVideoPlaying,
    setIsVideoPlaying
  } = useContext(GlobalContext)



  useEffect(() => {
    // code to run when the user navigates away from the page
    return () => {
      setIsVideoMode(false)
      setIsVideoPlaying(false)
    }
  }, [])


  useEffect(() => {
    if (tableData && QRCodesPresent && (QRCodesPresent.length > 0)) {
      const qrCodeUrl = QRCodesPresent[0]
      console.log(`qrCodeUrl: ${qrCodeUrl}`)

      // if it's a qr code for a checklist:
      if (qrCodeUrl.includes('CL.CHQN.IN')) {
        window.location = qrCodeUrl
      }

      // otherwise assume its a qr code for an asset:
      else {
        console.log("Trying to get asset from qr code url: ", qrCodeUrl)
        const assetId = getAssetIdFromUrl(qrCodeUrl)
        const isAssetValid = checkIfAssetExists(assetId, tableData)
        if (isAssetValid) {
          //console.log(`✅ valid asset [${assetId}]`)
          const questions: ObjectStringKeyAnyValue = findQuestionsForAssetId(assetId, tableData)
          //console.log(`questions for this asset: ${JSON.stringify(questions)}`)
          // if there is only one questions for this asset, redirect immediately.
          // if there are no questions, or multiple questions, show the modal.
          if (Object.keys(questions).length === 1) {
            const firstQuestionKey = Object.keys(questions)[0]
            const thisQuestion = questions[firstQuestionKey]
            const checklistId = thisQuestion.ChecklistId
            const checkPeriodsForThisChecklist: any = Object.values(tableData.ChecklistPeriods).filter((checkPeriod: any) => checkPeriod.ChecklistId === checklistId)
            const checkPeriodId = checkPeriodsForThisChecklist[0].Id
            const answersForThisQuestion: any = Object.values(tableData.ChecklistAnswers).filter((ChecklistAnswer: any) => ChecklistAnswer.QuestionId === firstQuestionKey)
            const answerId = answersForThisQuestion && answersForThisQuestion[0].Id
            const redirectUrl = `/checklists/${checkPeriodId}/${answerId}`
            //console.log(`redirecting to: ${redirectUrl}`)
            navigate(redirectUrl)
          } else {
            setQuestionsForAssetId(questions)
            setShowQuestionsModal(true)
          }

        } else {
          setShowInvalidAssetModal(true)
        }
      }
    }
  }, [QRCodesPresent, tableData])


  useEffect(() => {
    setIsVideoMode(true)
    setIsVideoPlaying(true)
    setQRCodesSearchOptions({
      'enabled': true,
      'expectedDomains': ['QRWL.ME', 'CHQN.IN', 'CL.CHQN.IN'],
      'searchArea': {
        top: 0,
        left: 0,
        width: 1,
        height: 1
      },
      'showSearchArea': false
    })

  }, [])





  return <div className={`w-full flex flex-col items-center justify-center`}>
    <div className={`max-w-3xl w-full pt-8 pb-8 px-3 flex flex-col gap-2 items-center justify-center`}>


      {questionsForAssetId && showQuestionsModal && <Modal showCloseButton={true} setShowModal={setShowQuestionsModal}>
        {Object.keys(questionsForAssetId).length === 0 ?
          <h4 className={`text-center font-righteous text-brandblue text-lg`}>Sorry, no questions have been associated with this asset</h4>
          :
          <div className={`flex flex-col gap-2`}>
            <h4 className={`text-center font-righteous text-brandblue text-lg`}>{Object.keys(questionsForAssetId).length} question(s) associated with this asset:</h4>
            <div className={`flex flex-col gap-2`}>
              {Object.keys(questionsForAssetId).map((key, index) => {
                const thisQuestion = questionsForAssetId[key]
                return <Button
                  key={index}
                  internalLinkUrl={`/checklists/${thisQuestion.ChecklistId}/${key}`}
                  text={`${thisQuestion.QuestionText} (${thisQuestion.ChecklistName})`}
                // size={'big'}
                />
              })}
            </div>
          </div>
        }
      </Modal>}


      {showInvalidAssetModal && <Modal><div className={`flex flex-col items-center justify-center gap-4`}>
        Invalid asset or checklist ID
        <Button
          text={'Ok'}
          onClick={() => setShowInvalidAssetModal(false)}
        />
      </div></Modal>}


      <HeaderDetails
        titleText={checklistId ?
          `Scanning QR code for checklist id: ${checklistId}` :
          `Scanning QR code`
        }
      />




      {/* {QRCodesPresent && JSON.stringify(QRCodesPresent)} */}

    </div>
    <FooterNavButtons
      backButtonUrl={checklistId ? `/checklists/${checklistId}/` : `/`}
      backButtonText={'Back'}
      // nextButtonAction={validate}
      nextButtonText={'Next'}
    />
  </div>
}
export default ScanQrCode