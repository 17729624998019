export const getAssetIdFromUrl = (dataUrl) => {
  if (dataUrl){
    const urlParts = dataUrl.split('/')
    const numberOfUrlParts = urlParts.length
    const assetId = urlParts[numberOfUrlParts - 1]
    return assetId
  } else {
    console.log('⛔️ Could not get asset ID. Invalid data URL')
    return null
  }
}