export const getAnswersForThisAssetId = (tableData, checkPeriodId) => {
    //console.log(`🥝 Getting asset ids from answers for checkPeriodId: ${checkPeriodId}`)

    const assetIds = {}

    if (tableData && tableData.ChecklistQuestions && tableData.ChecklistAnswers){
        Object.values(tableData.ChecklistAnswers).forEach((checklistAnswer) => {
            if (checklistAnswer.ChecklistPeriodId === checkPeriodId){
                const questionId = checklistAnswer.QuestionId
                const thisQuestion = tableData.ChecklistQuestions[questionId]
                const assetId = thisQuestion.FridgeId
                if (assetId){
                    assetIds[assetId] = {
                        pending: checklistAnswer.Pending,
                        answerId: checklistAnswer.Id,
                    }
                }
            }
        })
    }

    //console.log(`🍇 assetIds: ${JSON.stringify(assetIds)}`)
    return assetIds
}