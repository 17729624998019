import { dateDiff } from "./datediff"

export function checkIfAnswerIsLocked(
    questionAnswer: ObjectStringKeyAnyValue | null,
    setIsQuestionLocked: any,
    setLockedUntilText: any
) {
    if (questionAnswer && questionAnswer['LockedUntil']) {
        const currentTimeStamp = new Date().getTime()
        const lockedUntilTimeStamp = questionAnswer['LockedUntil']
        const dateDiffValue = lockedUntilTimeStamp - currentTimeStamp
        const dateDiffString = dateDiff(lockedUntilTimeStamp, currentTimeStamp, 'fullDifference')


        if (dateDiffValue > 0) {
            setIsQuestionLocked(true)
            setLockedUntilText(`${dateDiffString}`)
        } else {
            setIsQuestionLocked(false)
            setLockedUntilText('')
        }

    }
}


