import React, { useEffect, useState, Children, useContext } from 'react'
import { GlobalContext } from '../GlobalContext'
import XButton from '../formfields/XButton'

type Props = {
  children: any
  setShowModal?: any
  showCloseButton?: boolean
  closeButtonValue?: any,
  blurBackground?: boolean
}

function Modal({
  children,
  setShowModal,
  showCloseButton,
  closeButtonValue,
  blurBackground=true 
}: Props) {

  let outer_div_classes = `fixed z-50 top-0 left-0 min-h-5 w-full flex items-center justify-center`
  if(blurBackground) {
    outer_div_classes = `fixed z-50 top-0 left-0 min-h-full w-full flex items-center justify-center p-6 bg-black bg-opacity-60`
  }


  return <div className={outer_div_classes}>
    <div
      className="rounded hover:opacity-90-xl w-full max-w-lg p-6 bg-white divide-y divide-gray-500 shadow-2xl overflow-y-scroll relative"
      style={{
        maxHeight: '80vh'
      }}
    >

      {setShowModal && showCloseButton && <div className={`absolute right-0 top-0 m-1`}>
        <XButton clickFunction={() => {
        setShowModal(closeButtonValue || false)
      }} />
      </div>}

      <div className={`border-none`}>
        {children}
      </div>
    </div>
  </div>
}
export default Modal