import React, { useEffect, useState, useContext } from 'react'
import { Navigate, useNavigate, useParams } from 'react-router-dom'
import Button from '../formfields/Button'
import { GlobalContext } from '../GlobalContext'
import CloudformationOutputs from '../CloudformationOutputs.json'
import FooterNavButtons from '../shared-components/FooterNavButtons'
import HeaderDetails from '../shared-components/HeaderDetails'
import Card from '../ui-elements/Card'
import RedAlert from '../ui-elements/RedAlert'
import Spinner from '../ui-elements/Spinner'
import { getDate } from '../utils/getDate'
import YellowAlert from '../ui-elements/YellowAlert'
import FormatQuestionAnswer from '../dashboard/FormatQuestionAnswer'
import FormatQuestionAnsweredBy from '../dashboard/FormatQuestionAnsweredBy'
import XButton from '../formfields/XButton'
import Modal from '../ui-elements/Modal'
import AddButton from '../formfields/AddButton'
import DeleteButton from '../formfields/DeleteButton'
import GreenAlert from '../ui-elements/GreenAlert'
import ListAnswerNotes from './ListAnswerNotes'
import { useVideo } from '../context/video/VideoContext'
import ScanQrCode from './ScanQrCode'


type Props = {
    question: ObjectStringKeyAnyValue
    answerId?: string | null
    answerNote: string | null
    setAnswerNote: React.Dispatch<React.SetStateAction<any>>
    saveNote: AnyFunction
    setJpegString: React.Dispatch<React.SetStateAction<any>>
    resetQuestion: AnyFunction
    setAnswerPhoto: React.Dispatch<React.SetStateAction<any>>
}

function QuestionConfirmPage({
    question,
    answerId,
    answerNote,
    setAnswerNote,
    saveNote,
    setJpegString,
    resetQuestion,
    setAnswerPhoto
}: Props) {

    const {
        tableData,
        currentOrganisation,
        setIsVideoMode,
        setIsVideoPlaying
    } = useContext(GlobalContext)
    const navigate = useNavigate()
    const { checkPeriodId } = useParams()
    const [questionAnswer, setQuestionAnswer] = useState<ObjectStringKeyAnyValue | null>(null)
    const [showAddNote, setShowAddNote] = useState(false)
    const { QRCodesPresent, setQRCodesPresent, cameraState } = useVideo()

    useEffect(() => {
        setIsVideoMode(false)
        setJpegString(null)
        setAnswerPhoto(null)
    }, [])

    useEffect(() => {
        if (question && question.Id && answerId && checkPeriodId && tableData && tableData.ChecklistAnswers) {
            const thisAnswer = tableData.ChecklistAnswers[answerId]
            if (thisAnswer && thisAnswer.QuestionId === question.Id && thisAnswer.Pending !== true) {
                setQuestionAnswer(thisAnswer)
            }
        }
    }, [tableData, question, checkPeriodId, answerId])





    return <div className={`w-full flex flex-col gap-4`}>

        {!tableData && <Spinner><p>Loading...</p></Spinner>}
        {tableData && !questionAnswer && <RedAlert icon={true} fullwidth={true}>Invalid answer</RedAlert>}

        {questionAnswer && <div className={`w-full flex flex-col gap-4`}>
            <div className={`flex flex-row gap-4 justify-center items-center`}>
                <img src={`/icons/green-tick-icon.png`} alt={'Green tick icon'} className={`w-8 h-8`} />
                <p className={``}> This question has been answered:</p>
            </div>

            <Card>



                {/* <GreenAlert icon={true} fullwidth={true}>This question has been answered:</GreenAlert> */}

                <div className={`w-full flex flex-row gap-4 items-center`}>



                    {questionAnswer.ImageFileName && currentOrganisation &&
                        <img
                            // src={`https://placehold.co/200x400`}
                            src={`https://${CloudformationOutputs.ImageBucketName}.s3.eu-west-1.amazonaws.com/${currentOrganisation}/${questionAnswer.ImageFileName}`}
                            alt=''
                            className={`rounded bg-gray-300 min-w-12 min-h-12 max-w-36 max-h-36`}
                        />}

                    <div className={`w-full flex flex-col gap-2 
                   ${questionAnswer.ImageFileName && currentOrganisation ? 'items-start text-left' : 'items-center text-center'}
                    `}>


                        <h4 className={`font-righteous text-brandblue
                        ${questionAnswer.Answer instanceof Object || questionAnswer.Answer instanceof Array ? ' text-xl ' : ' text-4xl '}
                            `}>
                            <FormatQuestionAnswer
                                questionAnswer={questionAnswer}
                                isTemperature={question.QuestionFormat === 'FridgeTemperature' ? true : false}
                            />
                        </h4>



                        <p className={`text-xs text-gray-600 italic`}>Answered by
                            <FormatQuestionAnsweredBy questionAnswer={questionAnswer} />
                        </p>

                        {questionAnswer && questionAnswer['AnswerAlerts'] &&
                            <div className={`mt-1 w-full flex flex-col gap-2 items-start justify-start`}>
                                {questionAnswer && questionAnswer['AnswerAlerts'] &&
                                    Object.keys(questionAnswer['AnswerAlerts']).length > 0 &&
                                    <div
                                        className={`w-full flex flex-col gap-2 items-start justify-start`}>
                                        {Object.values(questionAnswer['AnswerAlerts']).map((alert: any, index: number) => {
                                            return <RedAlert
                                                key={index}
                                                icon={true}
                                                fullwidth={true}
                                                alignment={`left`}
                                                size={`small`}
                                            >{alert.message}</RedAlert>
                                        })}
                                    </div>}

                            </div>}

                    </div>
                </div>
                <div className={`mt-3 w-full flex mm:flex-row flex-col-reverse gap-2`}>

                    <DeleteButton
                        deleteFunction={resetQuestion}
                        functionParams={questionAnswer.Id}
                        fullwidth={true}
                        buttonText={`Reset answer`}
                        modalText={`Are you sure you want to reset this answer?`}
                        variant={`gray`}
                    />

                    <Button
                        text="Add text note"
                        onClick={() => setShowAddNote(true)}
                        fullwidth={true}
                    />

                    <Button
                        text="Add photo note"
                        internalLinkUrl={`/checklists/${questionAnswer.Id}/add-photo-note`}
                        fullwidth={true}
                    />
                </div>

                {showAddNote && <Modal showCloseButton={true} setShowModal={setShowAddNote}><div className={`w-full flex flex-col gap-4`}>
                    <h5 className={`text-lg font-righteous text-brandblue`}>Add a note</h5>

                    <div className={`flex flex-col gap-4`}>
                        <input
                            className={`bg-white text-center drop-shadow-lg border border-gray-300 text-gray-900 rounded hover:opacity-90-lg focus:ring-blue-500 focus:border-blue-500 block w-full text-lg px-5 py-3`}
                            onChange={(e) => setAnswerNote(e.target.value)}
                            type={`text`}
                            value={answerNote || ''}
                        />

                        <Button
                            text="Add"
                            onClick={() => {
                                saveNote()
                                setShowAddNote(false)
                            }}
                            variant={'primary'}
                            //size={'big'}
                            fullwidth={false}
                        />
                    </div>
                </div>
                </Modal>}

            </Card>
        </div>}


        {questionAnswer && !questionAnswer.Pending && questionAnswer.AnswerNotes && Object.keys(questionAnswer.AnswerNotes).length > 0 &&
            <ListAnswerNotes
                answerNotes={questionAnswer.AnswerNotes}
            />}

    </div >
}

export default QuestionConfirmPage