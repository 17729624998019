import Card from "../ui-elements/Card"

type Props = {
}

function NoActiveCheckPeriods({
}: Props) {

    return <div className={`animate-fadein w-full mt-2`}>
        <Card fullwidth={true}>
            <div className={` w-full flex flex-col gap-1 items-center justify-center`}>
                <p className={`text-4xl`}>🏆</p>
                <h4 className={`font-righteous text-brandblue text-2xl`}>Congratulations</h4>
                <p className={`text-lg`}>You've completed all your checklists!</p>
            </div>
        </Card>
    </div>
}
export default NoActiveCheckPeriods