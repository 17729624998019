import React, { useEffect, useState, useContext } from 'react'
import { Navigate, useNavigate, useParams } from 'react-router-dom'
import Button from '../formfields/Button'
import { GlobalContext } from '../GlobalContext'
import FooterNavButtons from '../shared-components/FooterNavButtons'
import HeaderDetails from '../shared-components/HeaderDetails'
import RedAlert from '../ui-elements/RedAlert'
import Spinner from '../ui-elements/Spinner'
import { subscribe } from '../utils/pubsub'
import Modal from '../ui-elements/Modal'
import ChecklistSummary from './ChecklistSummary'
import OrgMismatchDialog from '../shared-components/OrgMismatchDialog'


type Props = {
    orgMismatchDialog: string | null
    setOrgMismatchDialog: React.Dispatch<React.SetStateAction<any>>
}

function StartChecklist({
    orgMismatchDialog,
    setOrgMismatchDialog
}: Props) {

    const {
        tableData,
        setShowModal,
        sendMessageToWebsocket,
        userData,
        loginOptions,
        currentOrganisation
    } = useContext(GlobalContext)

    const navigate = useNavigate()
    const { checklistId } = useParams()
    const [thisChecklist, setThisChecklist] = useState<ObjectStringKeyAnyValue | null>(null)
    const [checkPeriodsForChecklist, setCheckPeriodsForChecklist] = useState<ObjectStringKeyAnyValue | null>(null)
    const [showNotFound, setShowNotFound] = useState(false)
    const [thisChecklistQuestions, setThisChecklistQuestions] = useState<null | Array<ObjectStringKeyAnyValue>>(null)
    const checklistFrequency = thisChecklist && thisChecklist['Schedule'] && thisChecklist['Schedule']['Frequency'] ? thisChecklist['Schedule']['Frequency'] : null

    useEffect(() => {
        if (checklistId && tableData && tableData.Checklists && userData) {

            if (tableData.Checklists[checklistId]) {
                setThisChecklist(tableData.Checklists[checklistId])
                setShowNotFound(false)
            } else {
                // console.log('⛔️ Checklist not found. Orgs for this user:')
                // console.log(userData.organisationsForUser)]
                // console.log(currentOrganisation)
                setShowNotFound(true)
            }

            if (tableData['ChecklistQuestions']) {
                let questionsArray: any[] = Object.values(tableData['ChecklistQuestions']).filter((question: any) => question.ChecklistId === checklistId)
                questionsArray.sort((a, b) => parseInt(a.QuestionOrder) - parseInt(b.QuestionOrder))
                setThisChecklistQuestions(questionsArray)
            }

            // filter check periods for this checklist
            const newCheckPeriodsForChecklist: ObjectStringKeyAnyValue = {}
            for (const [key, value] of Object.entries(tableData.ChecklistPeriods)) {
                if ((value as any).ChecklistId === checklistId) {
                    newCheckPeriodsForChecklist[key] = value
                }
            }
            setCheckPeriodsForChecklist(newCheckPeriodsForChecklist)
        }

    }, [checklistId, tableData, userData])


    const startAdhocChecklist = () => {
        setShowModal({ "spinner": 'Saving...' })
        const payload = JSON.stringify({
            action: "checklistsClientApp",
            subAction: "startAdhocChecklist",
            checklistId: checklistId
        })
        sendMessageToWebsocket(payload)

        // when table data is updated with a check period for this checklist, 
        // navigate to the checklist
        const unsubscribe = subscribe("tableUpdateItemAdded", data => {
            if (
                data.tableName === 'ChecklistPeriods' &&
                data.newRecord &&
                data.newRecord.Id &&
                data.newRecord.ChecklistId === checklistId
            ) {
                setShowModal(null)
                navigate(`/checklists/${data.newRecord.Id}`)
                unsubscribe()
            }
        })
    }

    return <div className={`w-full flex-1 h-full flex flex-col justify-between gap-2`}>


{orgMismatchDialog && <OrgMismatchDialog 
              orgMismatchDialog={orgMismatchDialog}
              setOrgMismatchDialog={setOrgMismatchDialog}
               />}


               

        <div className={`w-full flex flex-col items-center`}>
            <HeaderDetails
                titleText={`Start checklist: ${thisChecklist ? thisChecklist['ChecklistName'] : 'unnamed checklist'}`}
            />
            <div className={`max-w-3xl w-full p-5 flex flex-col gap-2 items-center justify-center text-center`}>

                {!tableData && <Spinner><p>Loading...</p></Spinner>}
                {showNotFound && <RedAlert>Checklist {checklistId} not found</RedAlert>}
                {thisChecklist && thisChecklistQuestions && thisChecklistQuestions.length === 0 && <RedAlert icon={true}>No questions have been set up for this checklist</RedAlert>}





                {thisChecklist && checklistFrequency === 'WEEKLY' &&
                    <div className={`flex flex-col gap-4`}>
                        <p>Current check periods:</p>

                        {checkPeriodsForChecklist && Object.values(checkPeriodsForChecklist).length > 0 ?
                            Object.values(checkPeriodsForChecklist).map((checklistPeriod: any, index: number) => {
                                return <ChecklistSummary
                                    key={index}
                                    checklistId={checklistPeriod.ChecklistId}
                                    thisChecklist={thisChecklist}
                                    checkPeriodId={checklistPeriod.Id}
                                />
                            }) :

                            <RedAlert>No active check periods for this checklist</RedAlert>}

                    </div>}

                {thisChecklist && checklistFrequency === 'ADHOC' &&
                    <div className={`flex flex-col gap-4`}>
                        <p>You are about to start this ad hoc checklist. Are you ready?</p>

                        {thisChecklist && <Button
                            onClick={startAdhocChecklist}
                            text={'Get started'}
                            size={'big'}
                            variant={`primary`}
                        />}
                    </div>}



            </div>
        </div>


    </div>
}

export default StartChecklist